@import '~antd/dist/antd.less';

body {
  font-family: 'Inter', sans-serif;
  background-color: #fafafa;
}

/* Utilitiy classes */

.spin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}
.item-center {
  align-items: center;
}
.d-flex {
  display: flex;
}

.pb-10 {
  padding-bottom: 10px;
}

.flex-grid .flex-grid-child {
  padding: 0 12px;
}
.fitImage img {
  object-fit: contain;
}
.align-center-v {
  display: flex;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.line-height-0 {
  line-height: 0;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.status {
  display: inline !important;
  font-size: 12px;
  font-weight: 500;
  background-color: #868eae10;
  padding: 4.5px 11.85px;
  border-radius: 15px;
}

.status.warning {
  background-color: #fa8b0c10;
  color: #fa8b0c;
}

.status.error {
  background-color: #ff4d4f10;
  color: #ff4d4f;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.text-center {
  text-align: center;
}

.form-control {
  width: 100%;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
}

.form-control::-webkit-input-placeholder {
  color: #b5b5b5;
  font-size: 11px;
}
.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b5b5b5;
  font-size: 11px;
}
#react-select-3-placeholder,
#react-select-5-placeholder {
  color: #b5b5b5;
  font-size: 11px;
}
.form-control::placeholder {
  color: #b5b5b5;
  font-size: 11px;
}
.pam-check {
  font-weight: 600;
}
.pam-check a {
  text-decoration: underline;
}
.pam-check .label {
  margin-left: 10px;
  margin-right: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

label {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 5px;
  display: inline-block;
}

.flex {
  display: flex;
}

.react-tel-input {
  width: 100% !important;
}

.ml-35 {
  margin-left: 35px;
}

.m-0 {
  margin: 0 !important;
}

.mr-20 {
  margin-right: 20px;
}

.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-13 {
  margin-top: 13px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px;
}
.max-1000 {
  max-width: 1000px;
}
.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-80 {
  margin-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-150 {
  padding-top: 150px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-200 {
  padding-bottom: 200px;
}
.mr-15 {
  margin-right: 15px;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

@media only screen and (max-width: 760px) {
  .mr-15 {
    margin-right: 5px;
  }
}
.error-text {
  color: red;
  font-size: 12px;
}

.underline {
  text-decoration: underline;
}
.small-link {
  font-size: 12px;
  color: #b5b5b5;
  font-weight: 300;
}

.react-tel-input input[type='text'],
.react-tel-input input[type='tel'] {
  height: 40px;
  box-shadow: none;
}

.react-tel-input .selected-flag {
  border: none;
  background-color: transparent;
  padding: 10px;
  height: 40px;
}
.react-tel-input .flag-dropdown {
  border: none;
  background-color: transparent;
}

/* default card style */

.ant-card-body {
  padding: 25px !important;
}

.mt-100 {
  margin-top: 100px;
}

.ant-card-head {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ant-card-head-title span {
  display: inline-block;
  margin-left: 15px;
  font-size: 11px;
  font-weight: 500;
  color: #868eae;
}

.ant-card-head .ant-card-extra {
  display: flex;
  align-items: center;
}

.ant-card-head .ant-card-extra a {
  color: #868eae;
}

.ant-card-extra .ant-dropdown-trigger {
  line-height: 0;
  order: 1;
  margin-left: 20px;
}

.sDash_unresizable {
  resize: none;
}

.css-14el2xx-placeholder {
  font-size: 11px;
  color: #b3b3b3 !important;
}

/* ant radio group */

.ant-radio-button-wrapper-checked {
  color: #fff !important;
}

.link-button {
  border-radius: 50px;
  background-color: #fef0f7;
  color: #e54f96 !important;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 20px;
  border: none;
  cursor: pointer;
}

/* card nav */

.card-nav ul {
  list-style: none;
  display: flex;
  margin: 0 -8px !important;
}

.card-nav ul li {
  padding: 0 8px !important;
  position: relative;
}

.card-nav ul li.arch{
  margin-left: auto !important;

  svg{
    margin-right: 5px !important;
  }
}

.card-nav ul li a {
  font-weight: 500;
  color: #9d9d9d;
  font-size: 14px;
}

.card-nav ul li a.active {
  color: #000000;
  font-weight: 500;
}

.card-nav ul li .active:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  background: #e54f96;
  left: 0;
  bottom: -10px;
}

/* chart growth upward */

.growth-downward p,
.growth-upward p {
  font-size: 14px;
  color: #000;
  margin: 0;
}

.growth-upward.active {
  p,
  h1 {
    color: #e54f96;
  }
}

.growth-downward h1 sub svg,
.growth-upward h1 sub svg {
  position: relative;
  top: 2px;
  font-size: 14px;
  font-weight: 600;
  left: 5px;
  bottom: 0;
}

/*
.growth-downward h1, .growth-upward h1 {
  font-size: 22px;
  margin: 6px 0 0;
} */

.growth-downward h1 sub {
  color: #ff4d4f;
}

.growth-upward h1 sub {
  color: #20c997;
}

/* Chart */

.chart-label {
  display: flex;
}

.chart-label .chart-label__single {
  align-items: center;
}

.chart-label .chart-label__single:not(:last-child) {
  margin-right: 40px;
}

.chart-label .chart-label__single p {
  margin: 0;
  color: #868eae;
}

/* revenue doughnut */

.revenue-doughnut {
  display: flex;
  justify-content: center;
}

.revenue-doughnut > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.revenue-doughnut .rd-labels {
  width: 100%;
  margin-top: 30px;
}

.revenue-chat-percentage span {
  display: block;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 15px;
}

/* progressbars */

.ant-progress {
  display: inline-flex !important;
  align-items: center;
}

.ant-progress > div {
  display: flex;
  flex-direction: column;
}

.ant-progress .ant-progress-outer {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.revenue-chat-percentage span {
  display: block;
  font-size: 13px;
  color: #868eae;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 20px;
}

.ant-progress-status-warning .ant-progress-bg {
  background: #fa8b0c;
}

/* progress bars */

.progress-primary .ant-progress-bg {
  background: #5f63f2;
}

.progress-secondary .ant-progress-bg {
  background: #ff69a5;
}

.progress-success .ant-progress-bg {
  background: #20c997;
}

.progress-success .progress-info .ant-progress-bg {
  background: #2c99ff;
}

.progress-warning .ant-progress-bg {
  background: #fa8b0c;
}

.progress-danger .ant-progress-bg {
  background: #ff4d4f;
}

/* color classes */

.color-primary {
  color: #5f63f2;
}

.color-secondary {
  color: #ff69a5;
}

.color-info {
  color: #2c99ff;
}

.color-warning {
  color: #fa8b0c;
}

.color-success {
  color: #20c997;
}

.color-danger {
  color: #ff4d4f;
}

.color-dark {
  color: #272b41;
}

.color-error {
  color: #f5222d;
}

.color-gray {
  color: #5a5f7d;
}

/* Button Styles */

.ant-btn.ant-btn-light:focus {
  background: #fff;
  color: #5a5f7d;
}

.button-example .ant-btn {
  margin: 4px;
}

.button-example .ant-btn-group .ant-btn {
  margin: 0;
}

.ant-btn-white {
  color: #5a5f7d !important;
  border-color: #e3e6ef !important;
}

.ant-btn-white:hover {
  color: #5f63f2 !important;
  background-color: #fff !important;
  border-color: #fff;
}

.ant-btn-white:focus {
  background-color: transparent !important;
}

.ant-btn-link {
  background-color: #fff;
  border-color: #fff;
  color: #5f63f2;
}

.ant-btn-link:hover {
  border-color: #fff;
  background: #fff;
}

.ant-btn-light,
.ant-btn-light:hover,
.ant-btn-link:hover,
.ant-btn-dashed {
  color: #5a5f7d !important;
}

.ant-btn-light {
  border-color: #e3e6ef;
  background: #f4f5f7 !important;
}

.ant-btn-light.btn-outlined {
  background: transparent !important;
}

.ant-btn-light.btn-transparent {
  background: #f4f5f715;
}

.ant-btn-dashed {
  border-width: 1px !important;
}

.rsvgButton {
  svg {
    width: 14px !important;
    height: 13px !important;
    margin-right: 8px;
  }
}

.ant-btn-primary[disabled] {
  color: #fff;
  background: #e54f9645;
}

.ant-btn-light[disabled] {
  background: #fff;
}

.ant-btn-round.ant-btn-sm {
  height: 38px;
}

.ant-btn-white[disabled] {
  background-color: transparent;
  opacity: 0.6;
}

.ant-btn-white[disabled]:hover {
  color: #5a5f7d !important;
}

.ant-btn-primary[disabled]:hover {
  color: #fff !important;
  background: #5f63f260 !important;
}

.btn-icon {
  padding: 0 13px;
}
.btn-icon.auto {
  height: auto;
  width: auto;
  padding: 8px;
}

.collapse-btn {
  padding: 0px !important;
  padding-top: 11px !important;
  svg {
    width: 40px !important;
    height: 40px !important;
  }
}

.ant-btn-primary.light {
  background-color: #fef0f7;
  color: #e54f96 !important;
}
.btn-inc,
.btn-dec {
  height: 38px;
  width: 38px;
  font-size: 20px;
  padding: 0 12px !important;
  border-radius: 10px !important;
  border: 0 none;
}

.btn-inc:hover,
.btn-dec:hover {
  background: #5f63f210 !important;
  border: 0 none !important;
}

.btn-inc:hover i,
.btn-inc:hover svg,
.btn-dec:hover i,
.btn-dec:hover svg {
  color: #5f63f2;
}

/* input styles */

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-picker-input > input::placeholder {
  color: #adb4d2 !important;
}

/* Calendar Styles */

.ant-picker-calendar-header .ant-select-selector {
  height: 32px !important;
}

.ant-picker-calendar-header .ant-select-selection-search-input {
  height: 30px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #fff !important;
  color: #5f63f2 !important;
}

/* pagination */

.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
}

.ant-pagination-item-active {
  background-color: #5f63f2 !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination .ant-pagination-options .ant-select-selection-item {
  font-size: 13px;
  line-height: 30px !important;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  height: 30px;
  line-height: 30px;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-options .ant-select-selector {
  border: 1px solid #f1f2f6 !important;
  background-color: #fff;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  color: #5a5f7d !important;
  line-height: 2.6;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link {
  display: block;
}

.ant-pagination-prev,
.ant-pagination-next {
  line-height: 28px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 0 none !important;
}

.ant-pagination .ant-pagination-item a {
  color: #5a5f7d;
}

.ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
  color: #5a5f7d;
}

.ant-pagination
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-pagination {
  margin: -4px -12px !important;
}

.ant-pagination-item,
.ant-pagination-options,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-next {
  margin: 4px !important;
}

.ant-table-pagination {
  margin-top: 30px !important;
}

/* Wizard Modal */
.submission-successModal {
  text-align: center;
}
.submission-successModal .icon-success {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #20c997;
}
.submission-successModal p {
  margin-bottom: 0;
}
.submission-successModal .icon-success svg {
  color: #20c997;
}
.submission-successModal .submission-action {
  margin: 30px -5px -5px -5px;
}
.submission-successModal .submission-action button {
  margin: 5px;
  height: 38px;
}

/* Tree Select */

.ant-tree-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px !important;
  line-height: 40px;
}

.ant-tree-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-tree-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}

/* radio style */

.ant-radio-checked .ant-radio-inner {
  border-width: 5px !important;
}

.ant-radio-inner::after {
  content: none !important;
}

/* Statistics */

.ant-statistic .ant-statistic-title {
  color: #9299b8;
}

.ant-statistic .ant-statistic-content span {
  font-size: 20px;
}

/* Steps */

.steps-action.justify-content-center {
  justify-content: center !important;
}

/* rate */

.ant-rate-star.ant-rate-star-zero span svg {
  color: #c6d0dc;
}

.ant-rate-star:not(:last-child) {
  margin-right: 2px !important;
}

.ant-rate-text {
  color: #5a5f7d;
}

/* result */

.ant-result-icon {
  margin-bottom: 20px !important;
}

.ant-result-title {
  font-weight: 500;
  margin-bottom: 10px;
}

.ant-result-extra {
  height: 34px;
  padding: 4px 10.72px;
}

.ant-result-content .ant-typography strong {
  font-weight: 500;
}

.ant-result-content .ant-typography:last-child {
  margin-bottom: 0;
}

/* form select */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 33px !important;
}

.ant-select-multiple .ant-select-selector {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.sDash_fullwidth-select {
  width: 100%;
}

/* Nasted Comments Styles */

.nested-comment-wrapper .comment-title {
  font-size: 12px;
  padding-bottom: 10px;
  margin-bottom: 22px;
  border-bottom: 1px solid #e3e6ef;
}

/* calendar style */

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}

.notes-month {
  text-align: center;
  font-size: 28px;
}

.notes-month section {
  font-size: 28px;
}

/* Breadcrumb demo */

.demo {
  margin: 16px;
}

.demo-nav {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8;
}

.demo-nav a {
  line-height: 30px;
  padding: 0 8px;
}

.app-list {
  margin-top: 16px;
}

/* Modal */

.ant-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-modal-body p:last-child {
  margin-bottom: 0;
}

.project-modal {
  padding: 5px !important;
}

.project-modal .projects-members {
  margin-top: -12px;
}

.project-modal .ant-form-item-control-input {
  margin-top: 10px;
  min-height: auto;
}

.project-modal .ant-select-selector {
  border-color: #e3e6ef;
}

.project-modal-footer {
  padding: 10px !important;
  text-align: left !important;
}

.sDash_addTask-modal {
  max-width: 390px;
}
.sDash_addTask-modal .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 0 !important;
}
.sDash_addTask-modal .ant-modal-header .ant-modal-title {
  font-size: 16px;
  font-weight: 500;
  color: #272b41;
}
.sDash_addTask-modal .sDash_addTask-modal-inner .ant-form-item {
  margin-bottom: 15px;
}
.sDash_addTask-modal .ant-modal-header .ant-modal-close-x svg {
  color: #9299b8;
}
.sDash_addTask-modal .ant-form-item-control-input {
  min-height: 36px;
}
.sDash_addTask-modal input::placeholder,
.sDash_addTask-modal textarea::placeholder {
  color: #9299b8;
}
.sDash_addTask-modal input,
.sDash_addTask-modal textarea {
  padding: 6px 20px;
  border-radius: 5px;
}
.sDash_addTask-modal textarea {
  resize: none;
  min-height: 125px;
}
.sDash_addTask-modal .sDash-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin: -6px;
}
.sDash_addTask-modal .sDash-modal-actions button {
  font-size: 14px;
  margin: 6px;
  height: 38px;
  border-radius: 5px;
}
/* slider */

.ant-slider-handle {
  margin-top: -6px !important;
}

/* tags */

.ant-tag {
  font-weight: 600;
  padding: 0 9.5px;
}

/* tabs */

.ant-tabs-tab span {
  display: flex;
  align-items: center;
}

/* list */

.ant-list-bordered {
  border-color: #e3e6ef !important;
}

.ant-list-item-meta {
  padding: 10px 24px;
  border-bottom: 1px solid #f1f2f6;
}

/* popover */

.pop-select {
  position: absolute !important;
  .ant-popover-inner {
    padding: 0px !important;
  }
}

.ant-popover {
  position: fixed;
  z-index: 99999;
}

.ant-popover-inner {
  padding: 15px;
  box-shadow: 0 3px 7px #22222220;
  border-radius: 6px;
}

.ant-popover-inner-content {
  padding: 0;
}

.ant-popover-inner .ant-popover-title {
  padding: 5px 10px 10px;
}

.ant-popover-inner .ant-popover-inner-content a {
  color: #5a5f7d;
}

/* Drawer */

.ant-drawer {
  z-index: 99999;
}

/* Select Dropdwon */

.ant-select-dropdown {
  padding: 18px 0 !important;
  box-shadow: 0 5px 20px #9299b820 !important;
  border-radius: 0 0 6px 6px !important;
}

.ant-select-item {
  min-height: 20px !important;
  padding: 4px 12px !important;
}

.ant-select-item-group {
  color: #9299b8;
}

.ant-select-item.ant-select-item-option-grouped {
  padding-left: 25px !important;
}

/* mail props dropdown */

.mail-props {
  padding: 5px 25px;
  border: 0 none;
  background: #fff;
  /* box-shadow: 0 5px 40px rgba(146, 153, 184, 0.25); */
}

.mail-props li {
  display: flex;
  margin-bottom: 12px;
}

.mail-props li span:first-child {
  margin-right: 50px;
  min-width: 40px;
}

.mail-props li span:last-child {
  color: #5a5f7d;
}

.mail-props li:last-child {
  margin-bottom: 0;
}

.mail-props li span {
  color: #9299b8;
}

/* Basic Dropdwon */

.ant-dropdown {
  box-shadow: 0 5px 30px #9299b820 !important;
}

.ant-dropdown.wide-dropdwon {
  min-width: 140px !important;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more {
  min-width: 220px !important;
  box-shadow: 0 17px 20px #9299b820;
}
.ant-dropdown.wide-dropdwon.kanbanCard-more a {
  padding: 10px 24px;
}

.ant-dropdown-menu {
  min-width: 200px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0;
}

.ant-dropdown-menu li {
  color: #5a5f7d;
  padding: 5px 25px;
}

.ant-dropdown-menu li:hover {
  background-color: #5f63f205;
}

.atbd-top-dropdwon .atbd-top-dropdwon__title {
  background: #f4f5f7;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  min-height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.atbd-top-dropdwon {
  width: 310px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav {
  height: 260px;
  overflow: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav.notification-list {
  padding: 0;
}
.notification .ant-scroll-number {
  padding: 0px;
  width: auto;
  height: auto;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li {
  width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li:last-child {
  margin: 0;
}

.atbd-top-dropdwon a.btn-seeAll {
  position: relative;
  width: calc(100% + 30px);
  left: -15px;
  right: -15px;
  height: calc(100% + 15px);
  bottom: -15px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #5f63f2;
  padding: 15px 0;
  border-radius: 0 0 6px 6px;
  background: #fff;
}

.atbd-top-dropdwon a.btn-seeAll:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 -15px 20px #9299b808;
  z-index: 1;
  content: '';
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a.btn-seeAll:hover:after {
  box-shadow: 0 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover {
  background: #fff;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover:after {
  opacity: 1;
  visibility: visible;
}

// .atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover {
//   background-color: #f4f4f4;
//   border-radius: 10px;
// }

.atbd-top-dropdwon .atbd-top-dropdwon__content {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon {
  width: 48px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon svg {
  width: 18px;
  height: 18px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.atbd-top-dropdwon .notification-text h1 {
  font-size: 14px;
  font-weight: 400;
  color: #5a5f7d;
  margin-bottom: 4px;
}

.atbd-top-dropdwon .notification-text h1 span {
  color: #5f63f2;
  font-weight: 500;
  padding-left: 0;
}

.atbd-top-dropdwon .notification-text p {
  font-size: 12px;
  color: #adb4d2;
  margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content img {
  max-width: 40px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
  margin: -4px 15px 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .ant-badge-count {
  font-size: 8px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  line-height: 6px;
  border-radius: 8px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p.ant-scroll-number-only-unit.current {
  height: 15px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 span {
  font-size: 12px;
  font-weight: 400;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.add-file-dropdown a {
  font-size: 14px;
  font-weight: 400;
}
.add-file-dropdown a svg {
  width: 14px;
}

.folder-dropdown {
  min-width: 200px !important;
}

/* Tooltip Styles */

.ant-tooltip .ant-tooltip-inner {
  min-height: 40px;
  padding: 6px 20px;
  font-size: 14px;
  color: #5a5f7d;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: 1px solid #f1f2f6;
  box-shadow: 0 8px 15px #9299b815;
}

.ant-tooltip .ant-tooltip-arrow {
  /* position: relative; */
}

.ant-tooltip .ant-tooltip-arrow:after {
  position: absolute;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
  width: 13px;
  height: 13px;
  background: #fff;
  content: '';
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  width: 10px;
  height: 10px;
  background: #fff;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow .ant-tooltip-arrow-content {
  border: 1px solid #f1f2f6;
  box-shadow: 0 0;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  top: -4.071068px;
}

/* Badge Styles */

.badge {
  font-size: 11px;
  font-weight: 500;
  padding: 0 6.5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.badge.badge-primary {
  background: #5f63f210;
  color: #5f63f2;
}

/* Cascade Styles */

.custom-cascade-render .ant-cascader-picker {
  width: 100%;
}

.ant-cascader-picker input::placeholder {
  color: #adb4d2;
}

/* Comment Styles */

.ant-comment-actions li {
  margin-bottom: 0 !important;
}

/* Radio Vertical Button */

.ant-radio-vertical .ant-radio-wrapper {
  display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
  margin-bottom: 14px;
}

/* Select Tree */

.ant-select-tree-list .ant-select-tree-node-content-wrapper:hover {
  background-color: #bae7ff !important;
}

.ant-select-tree-list .ant-select-arrow svg {
  font-size: 10px;
}

.ant-tree-select.ant-select-multiple .ant-select-selection-item {
  border: 0 none;
  border-radius: 3px;
  background: #f4f5f7;
  color: #5a5f7d;
  font-weight: 500;
}

/* Ant Switch */

.ant-switch:after {
  width: 14px !important;
  height: 14px !important;
}

.ant-switch-small:after {
  width: 10px !important;
  height: 10px !important;
}

/* Input Affix */

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 5px;
}

.ant-input-affix-wrapper .ant-input-prefix svg {
  color: #e3e6ef;
}

/* Space Item */

.ant-space-item .ant-btn span {
  font-size: 14px;
}

/* Pop confirm */

.pop-confirm .ant-btn {
  max-width: 90px;
  padding: 0px 35.5px;
  height: 44px;
}

.pop-confirm-top {
  margin-bottom: 10px;
}

.pop-confirm-bottom {
  margin-top: 10px;
}

.pop-confirm-top .ant-btn:not(:last-child),
.pop-confirm-bottom .ant-btn:not(:last-child) {
  margin-right: 10px;
}

.pop-confirm-left .ant-btn:not(:last-child),
.pop-confirm-right .ant-btn:not(:last-child) {
  margin-bottom: 10px;
}

/* Ant Upload */

.ant-upload .ant-btn {
  font-size: 14px;
  border-radius: 5px;
}

.ant-upload.ant-upload-select-picture-card {
  border-color: #e54f96 !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  height: 60px !important;
  margin-right: 30px;
}

.ant-upload.ant-upload-select-picture-card .anticon {
  margin-bottom: 8px;
}

.ant-upload .anticon svg {
  color: #9299b8;

}
.anticon svg{
  max-width: 18px !important;
}

.sDash_upload-basic .ant-upload.ant-upload-select {
  width: 100%;
  border: 1px solid #e3e6ef;
  border-radius: 4px;
}
.sDash_upload-basic .ant-upload.ant-upload-select .ant-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sDash_upload-basic .sDash_upload-text {
  font-size: 14px;
  color: #9299b8;
  padding-left: 15px;
}
html[dir='rtl'] .sDash_upload-basic .sDash_upload-text {
  padding-left: 0px;
  padding-right: 15px;
}
.sDash_upload-basic .sDash_upload-browse {
  font-size: 14px;
  color: #9299b8;
  display: inline-block;
  padding: 14px 23px;
  border-left: 1px solid #e3e6ef;
}
html[dir='rtl'] .sDash_upload-basic .sDash_upload-browse {
  border-left: 0 none;
  border-right: 1px solid #e3e6ef;
}
.ant-card.sDash_upload-form .ant-card-body {
  padding-bottom: 15px !important;
}
/* Ant Picker */

.ant-picker {
  border-color: #e3e6ef !important;
}

/* Ant Dropdown */

.ant-dropdown {
  box-shadow: 0 5px 20px #9299b820;
  background: #fff;
  border-radius: 6px;
  // padding: 15px 0 !important;
  border: 0 none;
}

.ant-dropdown a {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  font-weight: 400;
  color: #5a5f7d;
}

.ant-dropdown a i,
.ant-dropdown a svg,
.ant-dropdown a img {
  margin-right: 8px;
}

.ant-dropdown div {
  box-shadow: 0 0;
  border-radius: 5px;
}

/* Picker Under Input */

.ant-form-item-control-input .ant-picker {
  padding: 0 12px 0 0;
}

/* Leaflet COntainer */

.leaflet-container {
  z-index: 0;
}

/* Table Bordered*/

.table-bordered .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f8f9fb !important;
}

.table-bordered .ant-table-thead > tr > th {
  background: #fff;
  border-top: 1px solid #f1f2f6;
}

.table-bordered .ant-table-tbody > tr > td {
  border-color: #f1f2f6 !important;
}

.table-bordered .ant-table-thead tr th,
.table-bordered .ant-table-tbody tr td {
  padding: 16px 25px;
}

.table-bordered .ant-table-thead tr th:last-child,
.table-bordered .ant-table-tbody tr td:last-child {
  text-align: right;
}

/* Full Width Table */

.full-width-table .ant-card-body {
  padding: 0 !important;
}

.full-width-table .ant-table {
  border-radius: 10px !important;
}

.full-width-table .top-seller-table {
  min-height: 406px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell {
  white-space: normal;
  padding: 16px 15px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell:first-child {
  padding-left: 25px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell:last-child {
  padding-right: 25px;
}

.full-width-table .top-seller-table th {
  text-align: right;
  color: #272b41;
  font-weight: 500;
}

.full-width-table .top-seller-table td {
  color: #5a5f7d;
}

.full-width-table .top-seller-table .ant-table-tbody > tr > td {
  text-align: right;
}

.full-width-table .top-seller-table th:first-child,
.full-width-table .top-seller-table td:first-child {
  text-align: left !important;
}

.ant-page-header-heading-extra {
  display: flex !important;
}

/* Table Responsive */

.table-responsive .ant-table-content {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive .ant-table-content .ant-table-cell {
  white-space: nowrap;
  max-width: 300px;
  padding-right: 5px !important;

  h1 {
    white-space: break-spaces !important;
  }
}

.table-responsive .ant-table-content .ant-table-expanded-row .ant-table-cell {
  white-space: normal;
}

/* Rich TextEditor  */

.RichTextEditor__root___2QXK- {
  border: 0 none !important;
}

.RichTextEditor__root___2QXK- .EditorToolbar__root___3_Aqz {
  margin: 0;
  border-color: #f1f2f6;
}

.RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button {
  padding: 0;
  border: 0 none;
  background: #fff;
  margin-right: 8px;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx .Dropdown__value___34Py9 {
  border: 0 none;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx select {
  border-right-width: 0px;
}

.RichTextEditor__editor___1QqIU .DraftEditor-editorContainer {
  border: 0 none;
}

/* ChatBox Dropdwon */

.atbd-chatbox__messageControl {
  min-width: 210px;
}

.atbd-chatbox__messageControl ul li a {
  padding: 4px 24px;
}

.atbd-chatbox__emoji {
  margin: -4.48px 0;
  padding: 0 10px;
}

.atbd-chatbox__emoji ul {
  display: flex;
  align-items: center;
}

.atbd-chatbox__emoji ul li {
  display: inline-block;
}

.atbd-chatbox__emoji ul li a {
  display: block;
  font-size: 20px;
  padding: 4px 7px;
  background: #fff;
}

.atbd-chatbox__emoji ul li a:hover {
  background-color: transparent;
}

.atbd-chatbox__emoji ul li a svg {
  margin: 0;
}

.rdrMonths {
  flex-wrap: wrap;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-item {
  display: inline-flex;
}

.ant-menu-submenu-popup {
  z-index: 105;
}

.ant-menu-submenu-popup .ant-menu-sub {
  padding: 8px 0;
}

.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-item {
  display: block;
}

.ant-menu-submenu-popup .ant-menu-item {
  margin-bottom: 0 !important;
}
.ant-menu-sub.ant-menu-vertical {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}
.ant-menu-sub.ant-menu-vertical .ant-menu-item a {
  color: #5a5f7d;
}

/* page heading */

.ant-page-header {
  padding: 26px 25px 0px 25px !important;
}

.ant-page-header.header-boxed {
  padding: 26px 180px 26px 180px !important;
}

.ant-page-header-heading {
  align-items: center;
  margin: 0 !important;
}

.ant-page-header-heading-title {
  text-transform: capitalize;
  font-size: 24px !important;
}

/* Antd drag & drop */

.row-dragging {
  /* background: #fafafa; */
  /* border: 1px solid #ccc; */
  box-shadow: 0 15px 50px #9299b820;
  display: flex;
}

.row-dragging tr {
  box-shadow: 0 15px 50px #9299b820;
}

.row-dragging td {
  padding: 16px;
  color: #000;
  position: relative;
  z-index: 9999;
  opacity: 0.5;
  vertical-align: middle;
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #20c997;
  border-color: #20c997;
}

.row-dragging td .todos-action,
.row-dragging td .table-actions {
  display: none;
}

.row-dragging td .user-info figcaption {
  display: none;
}

.table-actions .feather {
  color: #767676 !important;
}

.row-dragging td .feather-move,
.row-dragging td .drag_email,
.row-dragging td .drag_company,
.row-dragging td .drag_designation,
.row-dragging td .drag_join-date,
.row-dragging td .active {
  display: inline-block;
  margin-top: 10px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* File Export Import Modal */
.sDash_export-wrap .ant-modal {
  width: 390px !important;
}
.sDash_export-wrap .ant-modal .ant-modal-header {
  border-bottom: 0 none;
  padding: 18px 30px 0 30px;
}
.sDash_export-wrap .ant-modal .ant-modal-body {
  padding: 25px 30px 30px 30px;
}
.sDash_export-wrap .ant-modal .sDash-button-grp {
  text-align: right;
}
.sDash_export-wrap .ant-modal .sDash-button-grp {
  margin: 20px -8px -8px -8px;
}
.sDash_export-wrap .ant-modal .sDash-button-grp button {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  height: 40px;
  padding: 0 16px;
  margin: 8px;
}
.sDash_export-wrap .ant-modal .sDash-button-grp button.ant-btn-white {
  color: #9299b8 !important;
}
.sDash_export-wrap .ant-form-item-control-input {
  min-height: 36px;
}

.sDash_export-wrap .ant-form-item-control-input input {
  font-size: 14px;
  font-weight: 400;
  padding: 6px 20px;
  border-radius: 5px;
  color: #9299b8;
}

.sDash_export-wrap .ant-select-single {
  width: 100% !important;
}

.sDash_export-wrap .ant-form .ant-form-item {
  margin-bottom: 15px;
}

.sDash_export-wrap .ant-select-single .ant-select-selector {
  padding: 0 20px;
  border-color: #e3e6ef !important;
}

.sDash_create-file .sDash-button-grp {
  text-align: right;
}

.sDash_create-file .ant-modal {
  width: 390px !important;
}
.sDash_create-file .ant-modal .ant-form-item {
  margin-bottom: 20px;
}
.sDash_create-file .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 6px;
}
.sDash_create-file .sDash-button-grp button {
  height: 40px;
  border-radius: 5px;
  margin: 5px;
}

.sDash_create-file .ant-form-item-control-input {
  border-radius: 5px;
}

.sDash_create-file .ant-form-item-control-input input {
  border: 1px solid #e3e6ef;
}

/* Task Modal */
.sDash_task-details .ant-modal-content .ant-modal-close {
  top: 10px;
}
.sDash_task-details .ant-modal-header {
  border-bottom: 0 none;
  padding: 30px 30px 0;
}
.sDash_task-details .ant-modal {
  width: 600px !important;
}
.sDash_task-details .ant-modal-header .ant-modal-title h4 {
  font-size: 20px;
  font-weight: 500;
  color: #272b41;
  margin-bottom: 4px;
}
.sDash_task-details .ant-modal-header .ant-modal-title .sub-text {
  font-size: 14px;
  font-weight: 400;
  color: #868eae;
}
.sDash_task-details .sDash_task-details-modal .sDash_task-details__label {
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}
.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea {
  padding: 10px 20px;
  min-height: 88px;
  width: 100%;
  border: 0 none;
  border-radius: 4px;
  background-color: #f4f5f7;
  resize: none;
}
.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea:focus {
  outline: none;
}
.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea::placeholder {
  color: #5a5f7d;
  font-size: 15px;
}
.sDash_task-details .ant-modal-body {
  padding: 14px 30px 30px 30px;
}
.sDash_task-details .ant-modal-body .sDash_checklist-block {
  margin-top: 26px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-wrap {
  position: relative;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
  position: absolute;
  width: 240px;
  padding: 18px;
  left: 0;
  top: 50px;
  box-shadow: 0 15px 30px #9299bb30;
  background-color: #fff;
  border: 1px solid #e3e6ef;
  border-radius: 6px;
  z-index: 222;
}

html[dir='rtl'] .sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
  left: auto;
  right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .add-checklist {
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 38px;
  border: 1px solid #e3e6ef;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action {
  margin-top: 15px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action a {
  position: relative;
  top: 3px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}
.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action a svg {
  color: #9299bb;
}
.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action .btn-add {
  margin-right: 15px;
}
html[dir='rtl']
  .sDash_task-details
  .ant-modal-body
  .sDash_checklist-block
  .addChecklist-form
  .addChecklist-form-action
  .btn-add {
  margin-left: 15px;
  margin-right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block button {
  height: 38px;
  padding: 0px 18.37px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row {
  margin-top: 26px;
}
.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item:not(:last-child) {
  margin-bottom: 30px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__top button {
  padding: 0px 16.32px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__title {
  font-size: 16px;
  font-weight: 500;
  color: #272b41;
  margin-bottom: 0;
}

.sDash_task-details
  .ant-modal-body
  .sDash_checklist-row
  .sDash_checklist__progress
  .ant-progress-inner
  .ant-progress-bg {
  height: 5px !important;
  background-color: #20c997;
}
.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist__progress .ant-progress .ant-progress-text {
  font-size: 12px;
  font-weight: 500;
  order: -1;
  margin: 0 10px 0 0;
}
html[dir='rtl']
  .sDash_task-details
  .ant-modal-body
  .sDash_checklist-row
  .sDash_checklist__progress
  .ant-progress
  .ant-progress-text {
  margin: 0 0 0 10px;
}
.sDash_task-details .ant-modal-body .sDash_checklist-tasks {
  margin: 5px 0 5px 0;
}
.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-color: #c6d0dc;
}
.sDash_task-details
  .ant-modal-body
  .sDash_checklist-tasks
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #20c997;
}
.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-checked:after {
  border-color: #20c997;
}

.sDash_task-details .ant-modal-body .sDash_checklist-item .sDash_checklist-tasks-wrap button {
  padding: 0px 35px;
}

.sDash_task-details .ant-modal-body {
  max-height: 800px;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px !important;
  }
}

.page-header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -6px -4px;
}

.page-header-actions button {
  font-size: 12px;
  font-weight: 500;
  height: 34px;
  padding: 0 12.32px;
  box-shadow: 0 3px 5px #9299b805;
  margin: 6px 4px;
}

.page-header-actions button.ant-btn-white:focus {
  background-color: #fff !important;
}

.page-header-actions button + button {
  margin-left: 8px;
}

.page-header-actions button.ant-btn-white svg {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  color: #5f63f2;
}

/* Layout Css */

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #272b41 !important;
}

.ant-menu-dark .ant-menu-item a {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark .ant-menu-item a:hover {
  color: #ffffff;
}

.ant-menu-dark .ant-menu-submenu span {
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.05);
}

.ant-menu-inline-collapsed-tooltip a {
  color: #000 !important;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: transparent;
}

/* Chart Label */

.chart-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  color: #5a5f7d;
}

.chart-label .label-dot {
  margin-right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.chart-label .label-dot.dot-success {
  background: #20c997;
}

.chart-label .label-dot.dot-info {
  background: #5f63f2;
}

.chart-label .label-dot.dot-warning {
  background: #fa8b0c;
}

/* NOtification CSS */

.ant-notification {
  z-index: 99999 !important;
}

.rdrInputRange {
  padding-left: 10px !important;
}

/* Overlay Dark */
.overlay-dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #10122130;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.overlay-dark.show {
  z-index: 999;
  opacity: 1;
  visibility: visible;
}

/* Responsive CSS */

@media only screen and (max-width: 1599px) {
  .ant-page-header.header-boxed {
    padding: 26px 130px 26px 130px !important;
  }
}
@media only screen and (max-width: 1500px) {
  .full-width-table .revenue-table {
    min-height: 100%;
  }
  .pop-confirm .ant-btn {
    padding: 0 20px;
    max-width: 60px;
  }
  .pop-confirm.pop-confirm-right {
    margin-left: 300px !important;
  }
  .pop-confirm.pop-confirm-bottom,
  .pop-confirm.pop-confirm-top {
    margin-left: 80px !important;
  }
}

@media only screen and (max-width: 1399px) {
  .ant-page-header.header-boxed {
    padding: 26px 50px 26px 50px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .ant-page-header {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 991px) {
  .ant-page-header.header-boxed {
    padding: 26px 30px 26px 30px !important;
  }
  .rdrMonths .rdrMonth {
    width: 100%;
    margin-bottom: 30px;
  }
  .rdrDateRangePickerWrapper.PreviewArea {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 20px;
  }
  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .ant-page-header-heading-left {
    max-width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px !important;
  }

  .ant-page-header-heading-left {
    max-width: 100%;
  }
  .ant-page-header-heading .ant-page-header-heading-title {
    margin-right: 0;
    white-space: normal;
    text-align: center;
  }

  .ant-card-body {
    padding: 20px !important;
  }
  .ant-card-head {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ant-pagination .ant-pagination-item,
  .ant-pagination .ant-pagination-item.ant-pagination-prev,
  .ant-pagination .ant-pagination-item.ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev {
    margin-right: 5px;
  }
  .ant-pagination li.ant-pagination-item,
  .ant-pagination li.ant-pagination-prev,
  .ant-pagination li.ant-pagination-next {
    height: 25px;
    min-width: 25px;
    line-height: 22px;
  }
  .ant-pagination li.ant-pagination-prev .anticon,
  .ant-pagination li.ant-pagination-next .anticon {
    vertical-align: 0.15em;
  }
  .ant-table-pagination {
    float: none !important;
    text-align: center;
  }
  .ant-table-pagination li.ant-pagination-total-text {
    display: block;
    margin-bottom: 8px;
  }
  .ant-table-pagination li {
    margin-right: 8px !important;
  }
  .ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px !important;
  }
  .ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 23px !important;
  }
  .rdrDefinedRangesWrapper {
    border-right: 0 none;
  }
}

@media only screen and (max-width: 575px) {
  .btn-inc,
  .btn-dec {
    height: 30px;
    width: 30px;
  }
  .ant-page-header {
    padding: 16px 20px 10px !important;
  }
  .ant-layout-header {
    height: auto;
  }

  .pop-confirm .ant-btn {
    max-width: 60px;
    padding: 0 20px;
  }
  .pop-confirm.pop-confirm-right {
    margin-left: 320px !important;
  }
  .pop-confirm.pop-confirm-bottom {
    margin-left: 95px !important;
  }

  .card-nav ul li.active:before {
    display: none;
  }
}

.bmzxig .ant-table tr th:first-child,
.bmzxig .ant-table tr td:first-child {
  padding-right: 15px;
}

/* Emprt */

.ant-empty .ant-empty-footer button {
  padding: 0px 15.58px !important;
}

/* Add Event Modal */
.addEvent-modal .ant-modal-header {
  padding: 20px 25px;
}
.addEvent-modal .ant-modal-header .ant-modal-close-x svg {
  color: #5a5f7d;
}
.addEvent-modal .ant-modal-header .ant-modal-title {
  font-size: 15px;
  font-weight: 500;
  color: #272b41;
}

.addEvent-modal .ant-modal-body {
  padding: 23px 25px;
}

/* Event Dropdown */

.event-dropdown {
  min-width: auto !important;
  max-width: 450px;
  padding: 0 !important;
  margin: 6px 0 0 !important;
  box-shadow: 0 10px 40px #9299b820 !important;
}

.event-dropdown div {
  border-radius: 8px;
}

.event-dropdown .ant-card {
  width: 100% !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 479px) {
  .placement-confirm {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -10px 0 -5px;
  }
  .placement-confirm .pop-confirm {
    flex: 0 0 50%;
  }
  .pop-confirm .ant-btn {
    display: block;
    padding: 0 20px;
    max-width: 80px;
    min-width: 80px;
    margin: 10px;
  }
  .pop-confirm.pop-confirm-right {
    margin-left: 0px !important;
  }
  .pop-confirm.pop-confirm-top {
    margin-left: 0px !important;
  }
  .pop-confirm.pop-confirm-bottom {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .ant-select {
    width: 100% !important;
  }
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
  .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
  }
  .atbd-top-dropdwon {
    width: 280px;
    min-width: 180px;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__title {
    min-height: 40px;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__nav li:not(:last-child) {
    margin-bottom: 10px;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
    padding: 10px 0px;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__content img {
    margin-right: 15px;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
    margin-left: 0;
  }
  .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .atbd-top-dropdwonText {
    min-width: 155px;
  }
  .ant-drawer-content-wrapper {
    width: 260px !important;
  }
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 0;
  }
}

@media only screen and (max-width: 379px) {
  .ant-card-head-wrapper .ant-card-extra .ant-radio-button-wrapper {
    height: 32px !important;
    line-height: 30px !important;
  }
  .ant-notification-notice {
    width: 275px;
  }
}

@media (max-width: 991px) {
  .mail-sideabr {
    box-shadow: 0 0 10px #00000020;
  }
}

/* Invoice Print Style */
@media print {
  .invoice-area .ant-page-header {
    display: none;
  }
  .customizer-trigger {
    display: none;
  }
}

.tbl-label {
  background-color: #dfe5f5;
  border-radius: 5px;
  padding: 3px 5px;
  font-size: 11px;
  margin: 5px;
  display: block;
}

// .tbl-label.interested-lead {
//   background-color: #dcf7a5 !important;
// }
// .tbl-label.in-progress-leads {
//   background-color: #feddaf !important;
// }
.tbl-label.bad {
  background-color: #d3f3ff !important;
}
.tbl-label.converted-leads {
  background-color: #ffcee5 !important;
}

.complete-task {
  border: 1px solid transparent !important;
  .active {
    display: block;
  }
  .hover {
    display: none;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    border: 1px solid #a3c856 !important;
    color: #a3c856 !important;
    .active {
      display: none;
    }
    .hover {
      display: block;
      color: #a3c856 !important;
    }
  }
}

.table-wrapper {
  display: flex;
  background-color: #fff;
}

table tbody tr.expand-parent td:last-child {
  border-right: none !important;
}

table tbody tr.expand-parent td {
  background-color: #F7FDFF !important;
}

.table-responsive .ant-table-content .ant-table-expanded-row .ant-table-cell {
  padding: 0px !important;
  border: 1px solid #f7c5dd;
}

.table-wrapper .table-responsive {
  width: 100%;
}

@media (min-width: 1025px) {
  .ant-table-expanded-row {
    display: none !important;
  }
}

.view-header {
  height: 46px;
  width: 100%;
  background-color: #fff;
}

.view-list {
  display: flex;
  margin-bottom: 15px;
  .label {
    width: 140px;
    min-width: 140px;
    display: flex;
    .text {
      margin-left: 10px;
    }
  }

  span {
    margin: 0 15px 0 15px;
  }
}

.ant-pagination {
  margin: 10px -4px !important;
}

.ant-pagination-item-active {
  background-color: #e54f96 !important;
}

.ant-btn.green {
  background-color: #009500 !important;
  border-color: #fff !important;
}

.ant-btn.orange {
  background-color: #f9ab56 !important;
  color: #fff !important;
  // padding: 0 10px !important;
}
.ant-btn.gray {
  background-color: #ebebeb;
  color: #000 !important;
  padding: 0 10px !important;
}

.shadowCard {
  box-shadow: 1px 1px 5px #00000029;
  border-radius: 5px;
}

.radius-5 {
  border-radius: 5px;
}

.radius-50 {
  border-radius: 50%;
}

.ant-table-expanded-row .ant-card-head {
  display: none !important;
}
.ant-table-expanded-row .ant-table-cell {
  padding: 0 !important;
}

.pam-header .ant-page-header-heading-title {
  font-size: 18px !important;
}

.btn-transparent button {
  background-color: transparent !important;
  padding: 0px 12px;
}

.btn-transparent svg {
  width: 20px !important;
  height: 20px !important;
}

.team-point {
  color: #000;
}

.team-point b {
  color: #e54f96;
}

textarea.ant-input {
  border-radius: 5px !important;
}

.ant-upload.ant-upload-drag {
  border-radius: 5px;
  border-color: #89003e;
}

.form-list .ant-list-item-meta-description {
  color: #000;
  font-weight: 300 !important;
}
.form-list .ant-list-item-meta {
  padding: 0px;
  border: none;
}

.form-list .ant-list-item {
  background-color: #fff;
  border: none;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.ant-upload-list-picture-card {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.ant-upload.ant-upload-select-picture-card {
  border-radius: 5px;
  border-color: #e54f96;
  width: 49%;
}

.ant-upload-list-picture-card .ant-upload-list-item img {
  border-radius: 10px !important;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px;
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.ant-upload-list-picture-card-container {
  width: 60px;
  height: 60px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.pam-check .label {
  font-weight: 300;
}

.ant-tooltip .ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff !important;
  border-radius: 5px;
  font-size: 12px;
  border-color: rgba(0, 0, 0, 0.85);
  a {
    color: #fff !important;
  }
}
.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background-color: rgba(0, 0, 0, 0.85);
}

.ant-radio-group {
  margin-top: 6px;
}

.css-1rhbuit-multiValue {
  background-color: #dfe5f5 !important;
}
.ant-modal-footer,
.ant-modal-header {
  border: none;
}

.ant-modal-title {
  font-size: 26px;
  font-weight: 700;
}

.ant-modal-header,
.ant-modal-footer,
.ant-modal-body {
  padding: 0px;
}

.ant-modal-content {
  padding: 40px;
}

.ant-modal .ant-modal-header {
  margin-bottom: 30px;
}

.ant-modal-footer button {
  height: auto;
  padding: 10px 15px;
  border-radius: 5px;
}

.ant-modal-footer {
  margin-top: 15px;
}

.searchLabel {
  position: absolute;
  z-index: 9;
  top: -8px;
  background-color: #fafafa;
  left: 20px;
  font-size: 11px;
}

small {
  color: #919191;
}

.m0 {
  margin: 0px !important;
}

.p0 {
  padding: 0px !important;
}

.max-1200 {
  max-width: 1200px;
}

.z9 {
  z-index: 9;
  position: relative;
}

.boxed-card {
  border-radius: 10px;
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
  box-shadow: 0px 3px 19px #00000029;
}

.order-trash-btn {
  position: absolute;
  top: -14px;
  right: -18px;
  height: 40px;
  width: 40px;
  padding: 0 !important;
}

.message_holder {
  border: 1px solid #ddd !important;
  border-radius: 7px;
  padding: 0 !important;

  .ant-card-body {
    padding: 0 !important;
  }

  textarea {
    border: none;
    border-radius: 0px;
  }
}

.message_header {
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 5px 15px;
  h1 {
    margin: 0;
    font-size: 12px;
    font-weight: 300;
  }

  .css-1pahdxg-control:hover,
  .css-1s2u09g-control {
    border-color: #fff !important;
  }

  .css-b62m3t-container {
    outline: none !important;
  }

  .css-14el2xx-placeholder {
    font-size: 12px;
    font-weight: 300;
  }
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}

.big-title .ant-page-header-heading-title {
  font-size: 24px !important;
}

.ant-select-selection-placeholder {
  color: #000;
  font-weight: 300;
}
.ant-select-single .ant-select-selector {
  padding-left: 0px !important;
}

.ant-select-arrow {
  top: 46% !important;
}

.upload-csv {
  background-color: #fff7fb !important;
  padding: 12px 30px;
  text-align: left !important;
}

.ant-modal .ant-modal-content {
  margin-top: 100px !important;
}

.hover-card {
  .ant-card-cover {
    height: 350px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }

  .ant-card-head {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    // background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

// Landing page style

.gjs-one-bg {
  background-color: #fff !important;
}

.gjs-pn-views {
  width: 50px !important;
  height: 100% !important;
  border: 1px solid #ebebeb;
  margin-top: 39px;

  .gjs-pn-buttons {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    height: 100%;

    span {
      margin-bottom: 10px;
    }
  }
}

.gjs-pn-commands {
  width: 100% !important;
}

.gjs-pn-options {
  right: 0 !important;
}

.gjs-pn-views-container {
  padding: 0px !important;
  margin-top: 42px !important;
}

.gjs-pn-views-container {
  margin-right: 50px !important;
  width: 16%;
}

.gjs-pn-btn.gjs-pn-active {
  box-shadow: none;
}

.gjs-pn-commands {
  box-shadow: none !important;
  border-bottom: 1px solid #ebebeb;
}

.gjs-pn-btn svg {
  fill: none !important;
}

.gjs-pn-views-container {
  width: 15.3% !important;
}

.gjs-two-color,
.gjs-block-category .gjs-title,
.gjs-block-categories {
  background-color: #f7f7f7 !important;
}

.gjs-block-category.gjs-open,
.gjs-block-category .gjs-title {
  border: none !important;
}

.gjs-block {
  border-color: #e4e4e4 !important;
  box-shadow: none !important;
}

.gjs-pn-views-container {
  box-shadow: none !important;
  border-left: 1px solid #ebebeb;
  border-radius: 3px !important;
}

.gjs-two-color,
.gjs-pn-btn,
.gjs-block.fa,
.fa-map-o:before,
.fa-chain:before,
.fa-link:before,
.fa-youtube-play:before,
.gjs-fonts::before,
.gjs-block-label {
  color: #404040 !important;
}

.gjs-pn-panel {
  background-color: #fff !important;
}

.gjs-pn-btn.gjs-pn-active {
  background-color: #ebebeb !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.gjs-layer-title {
  background-color: #fff !important;
  border-bottom: 1px solid #ebebeb !important;
}

.gjs-sm-sector-title {
  background-color: #f7f7f7 !important;
  border-color: #ebebeb !important;
}

.gjs-pn-panel {
  background-color: #f7f7f7 !important;
}

.gjs-field {
  border: 1px solid #ebebeb !important;
  background-color: #fff !important;
}

.gjs-radio-item-label {
  margin: 0;
}

.gjs-category-open,
.gjs-block-category.gjs-open,
.gjs-sm-sector.gjs-sm-open {
  border: none !important;
}

.gjs-sm-field.gjs-sm-composite,
.gjs-sm-composite.gjs-clm-select,
.gjs-sm-composite.gjs-clm-field {
  background-color: #ebebeb !important;
  border-color: #e5e5e5 !important;
}

.gjs-field .gjs-d-s-arrow {
  border-top-color: #000 !important;
}

.gjs-field-arrow-u {
  border-bottom-color: #000 !important;
}

.gjs-field-arrow-d {
  border-top-color: #000 !important;
}

.gjs-sm-field input,
.gjs-clm-select input,
.gjs-clm-field input,
.gjs-sm-field select,
.gjs-clm-select select,
.gjs-clm-field select {
  color: #000 !important;
}

.gjs-three-bg {
  background-color: #ffd6e4 !important;
  color: #000 !important;
}
.gjs-clm-tags #gjs-clm-close,
.gjs-clm-tags #gjs-clm-checkbox {
  color: #000 !important;
}

.gjs-field-checkbox input:checked + .gjs-chk-icon {
  border-color: #000 !important;
}

// .gjs-one-bg {
//   background-color: #fff !important;
// }

.ant-table-row.ant-table-row-level-1 td {
  background-color: #fff5fa;
}

.small {
  font-size: 11px;
  color: #909090 !important;
}
.ant-select.full {
  width: 100% !important;
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    padding: 0 10px !important;
  }
}

.mail-chimp-account {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;

  .ant-select-selector {
    border: none !important;
    border-left: 1px solid #ddd !important;
  }
  .ml-logo {
    width: 100px;
    padding: 5px 6px 0;

    svg {
      width: 100%;
      height: 25px;
    }
  }
}

.ant-card-bordered {
  border-radius: 7px !important;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.kjyZTV .site-sub-header {
  .ant-page-header-heading-left,
  .ant-page-header-heading-title,
  .ant-page-header-heading-sub-title {
    display: block;
    color: #000;
  }

  .ant-page-header-heading-left {
    margin-right: 20px;
  }

  .ant-page-header-heading-title {
    font-size: 15px !important;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
  }

  .ant-page-header-heading-sub-title {
    font-size: 12px !important;
    white-space: initial;
  }
}

.integration-actions button {
  background-color: transparent !important;
}

.mailchimp-activity {
  padding: 20px;
  .ant-timeline-item-content {
    margin-left: 45px !important;
  }

  .activity-date {
    padding-bottom: 30px;
    margin-left: -10px;
  }

  .ant-timeline-item-head-blue {
    color: #ccc !important;
  }
}
.center-loading {
  text-align: center;
  padding-top: 30px;
}

.ant-collapse-header h1 {
  margin: 0 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0 !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 15px 0 !important;
}

.kjyZTV .ant-page-header.m-0 {
  margin: 0px !important;
}

.drag-line {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 10px;
  background-color: #fff;
}

.drag-handle {
  cursor: move;
  margin: 12px 20px 0 40px;
}

.stages_sortable {
  list-style: none !important;
  li {
    list-style: none !important;
  }
}

.ml-80 {
  margin-left: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.merge-lists .ant-radio-group label {
  font-weight: 400 !important;
}

.site-page-header.sub_line .ant-page-header-heading-left {
  display: block !important;
  .ant-page-header-heading-sub-title {
    display: block !important;
  }
}

.form-control.grey {
  border: none !important;
  background-color: #ededed;
}
.gray-select {
  .css-1s2u09g-control {
    border: none !important;
    background-color: #ededed;
  }
}

.notification-page .not-read {
  color: #e54f96 !important;
}

.notification-page .read {
  color: #808080 !important;
}

.notification-page {
  padding-left: 15px !important;

  .ant-timeline-item-head-custom {
    top: 15px !important;
  }

  a {
    color: initial !important;
  }

  .ant-timeline-item {
    padding-bottom: 5px !important;
  }

  .notification-content {
    padding: 5px 10px;
    border: 1px solid #fff;
    margin-left: 10px;
    .text-right {
      display: none;
      margin-top: 5px;
    }
    &:focus,
    &:active,
    &:hover {
      .text-right {
        display: block;
      }
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    [type='button'] {
      border: 1px solid #f1f1f1 !important;
    }
  }
}

.brd-btn {
  border: 1px solid#dbdbdb !important;
}
.w170 {
  width: 170px !important;
}

.messageWrapper {
  background-color: #f8f8f8;
  padding: 20px;
  border: 1px dashed #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.pr-border {
  border-bottom: 0;
  border: 1px solid #eaeaea !important;
  background-color: #fff !important;
  margin-bottom: 10px;
  border-radius: 10px !important;
  padding: 20px;
  .ant-collapse-header {
    padding: 0 !important;
  }
}
.red {
  color: #e54f96 !important;
}

.kjyZTV .ant-page-header.mb-0,
.mb-0 {
  margin-bottom: 0 !important;
}
.funnel-create-button {
  position: relative;
  button {
    position: absolute;
    margin-top: 7px;
    right: 300px;
    z-index: 9;
    svg {
      width: 22px;
      height: 22px;
    }
  }
  button.back {
    left: 12px;
    right: auto;
  }
}

.white-spin .ant-spin-dot-item {
  background-color: #fff !important;
}

.step-container {
  border-radius: 5px;
  .ant-tabs-nav {
    background-color: #e54f96 !important;
    margin: 0 !important;
    border-radius: 5px 5px 0 0 !important;
    padding: 10px 10px 0 10px !important;

    .ant-tabs-tab {
      background-color: transparent !important;
      border: none !important;
      color: #fff;
      border-radius: 5px 5px 0 0 !important;
      padding: 16px 16px;

      &.ant-tabs-tab-active {
        background-color: #f8f8f8 !important;
        color: #000 !important;
        .ant-tabs-tab-btn {
          color: #000 !important;
        }
      }
    }

    .ant-tabs-tab-btn {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: 600;

      svg {
        margin-right: 5px;
      }
    }
  }
  .page-title {
    width: 290px;
    color: #fff;
    font-size: 12px;
    padding-bottom: 10px;
  }

  .ant-layout-sider {
    background-color: #ededed !important;
    padding: 0 !important;
  }

  .ant-layout-content {
    background-color: #fff;
  }

  .step-wrapper {
    border-bottom: 1px solid #ddd;
    padding: 10px 10px;
    cursor: pointer;
    .handle {
      margin-top: 8px;
    }

    &:hover {
      background-color: #f8f8f8;
      .step-action {
        display: block !important;
      }
    }

    .step-action {
      position: absolute;
      right: 0;
      display: none !important;
      padding: 10px 0;
    }
    .ant-btn {
      border: none !important;
    }

    .drag {
      padding: 2px 0;
    }

    &.active {
      background-color: #fff !important;
      color: #e54f96 !important;
    }
  }

  .step-button {
    margin: 20px 20px 0 20px;
    color: #000;
    border-color: #000;
    width: 85%;
    padding: 10px 12px;
    height: auto;
    // padding: 5px 10px;
    // display: flex;
    // border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
    border-width: 2px;
  }
}

.step-content {
  .ant-tabs-nav {
    background-color: #f8f8f8 !important;
    border-radius: 0px !important;
    justify-content: space-between;
  }

  .ant-tabs-nav-wrap {
    flex: initial !important;
  }
}
.tab-wrap {
  border-bottom: 1px solid #ddd;

  .ant-tag-checkable-checked {
    background-color: transparent;
    color: #000;
    border-bottom: 2px solid #e54f96;
  }

  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    background-color: transparent;
  }

  .ant-tag-checkable {
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.step-content {
  .page-title {
    color: #000;
  }

  .ant-card-body,
  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #fff !important;
  }

  .ant-tabs-tab-btn {
    color: #000;
  }
}

.ant-tabs-content {
  background-color: #fff;
  border: 1px solid #f2f2f1;
  border-top: none !important;
}

.title-setting {
  font-size: 14px;
  background-color: #f8f8f8;
  padding: 20px;
}

.template-list-wrapper {
  .ant-card {
    height: 100%;
    text-align: center;

    .ant-card-cover {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ant-card-head {
      position: absolute;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: none;
      .ant-card-extra {
        display: block;
        button {
          width: 145px;
          margin-bottom: 5px;
        }
      }
    }

    &:hover {
      .ant-card-head {
        z-index: 9;
        display: flex;
      }
      .ant-card-cover {
        postion: relative;
        &::after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

.nots-lists {
  .ant-card-head-title {
    border-bottom: none !important;
    margin-top: 15px;
    padding-bottom: 0 !important;
  }
}

.not-label-wrapper {
  display: inline-flex !important;
  flex-wrap: wrap;
  gap: 7px;

  .ant-tag {
    background-color: rgb(92 92 92 / 15%);
    border-radius: 10px;
    border: none;
    font-size: 10px;
    font-weight: 400;
    margin: 0;
  }

  .tag-input {
    width: 150px;
    border-radius: 20px;
    border-color: #d4d4d4;
    font-size: 12px;
  }

  .site-tag-plus {
    align-items: center;
    display: inline-flex !important;
    padding: 4px;
  }
}

.nav-labels {
  li {
    &:hover {
      .delete-label {
        display: inline-flex;
      }
    }
  }

  .delete-label {
    display: none;
    svg {
      margin-right: 2px !important;
    }
  }
}

.profile-mata {
  .ant-card-meta-title {
    margin-bottom: 0px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .ant-card-meta-description {
    color: #000000 !important;
    font-weight: 600 !important;
  }

  svg {
    margin-bottom: -3px !important;
  }
}

.profile-card {
  .profile-photo {
    .ant-avatar {
      position: absolute;
      border: 1px solid #ddd;
      background-color: #fff;
      border-radius: 50%;
      top: -25px;

      img {
        padding: 3px;
        border-radius: 50%;
      }
    }

    .user-name {
      margin-left: 140px !important;
      margin: 0px;
    }
  }

  a {
    color: #000 !important;
  }

  .ant-card-body {
    padding-top: 35px !important;
  }
}

.payment-history {
  table {
    border: 1px solid #f0f0f0;
  }
  margin-bottom: 10px;
  // border-radius: 10px;
}

svg {
  margin-bottom: -3px !important;
}
.account-payment {
  #Ellipse_84 {
    fill: green;
  }
}
.max-120 {
  max-width: 170px;
}

.ant-inp-p-10 {
  .ant-input {
    padding: 10px;
  }
}

.hover-card {
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);

  .ant-card-body {
    padding: 40px !important;
  }
}

.round-logo {
  background-color: #fff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  padding: 10px;
  border: 1px solid #ddd;
  position: absolute;
  bottom: -30px;
  z-index: 9;
  left: 0;
  right: 0;
  margin: auto;
  svg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.relative-position {
  position: relative;
}
.pt-50 {
  padding-top: 50px;
}
.pt-30 {
  padding-top: 30px;
}

.pr-50 {
  padding-right: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.premium-card {
  display: flex;
  flex-direction: row-reverse;
  .ant-card-body {
    padding: 40px !important;
    min-width: 50%;
  }

  .ant-card-cover {
    display: none;
    @media (min-width: 1450px) {
      display: block;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.feature-list {
  margin-top: 10px;
  .ant-list-item-meta {
    padding: 0 !important;
    border: none !important;
  }

  .ant-list-items{
    margin-top: 25px;
  }

  .ant-list-item {
    border: none !important;
    padding: 0 !important;
    margin-bottom: 10px!important;
  }

  .ant-list-item-meta-description{
    color: #5A5A5A;
    padding-right: 20px;
  }

  .ant-list-item-meta-avatar {
    margin-top: 4px !important;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.t-1 svg {
  margin: 0 !important;
}
.t-2 svg {
  margin: 5px !important;
}

.t-3 svg {
  margin-bottom: 1px !important;
}

.pointer {
  cursor: pointer;
}

.white {
  color: #fff !important;
  letter-spacing: 1px;
}

.premium-icon svg {
  height: 17px !important;
  width: 100% !important;
  margin-bottom: 0px !important;
}

.filepond--credits {
  display: none !important;
}

.premium-feature-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gjs-selected {
  outline: 2px solid #e0027a !important;
}

.invited-name {
  font-size: 12px;
  font-weight: 300;
  color: #000;
  background-color: #d8d8d8;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.w100p {
  width: 100%;
}
.container-404 {
  max-width: 500px;
  margin: auto;
  text-align: center;
  padding: 10% 0 0 0;

  svg {
    width: 100%;
    height: 100%;
  }
}
.pb-100 {
  padding-bottom: 100px;
}

.member-profile {
  .ant-card-body {
    padding: 25px !important;
    margin: 0 !important;
  }
  .ant-card-meta {
    margin: 0 !important;
  }

  .ant-card-meta-avatar {
    line-height: initial !important;
  }

  .infos svg {
    color: #e54f96;
    font-size: 16px;
    width: 20px;
    height: 20px;
    margin-top: 3px !important;
    margin-right: 5px !important;
  }

  .ant-card-meta-avatar {
    margin-right: 20px !important;
  }
}

.point span {
  color: green;
  font-size: 24px;
}

.no-view .ant-table-tbody .user-info {
  cursor: pointer;
}

.bswDAJ .no-margin.ant-page-header {
  margin-bottom: 5px !important;
}
.no-margin {
  margin: 0 !important;
}

.link-text {
  font-size: 0.75rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  outline-offset: 0.125rem;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}

.ant-input:placeholder-shown,
.ant-picker-input > input:placeholder-shown {
  /* Internet Explorer 10-11 */
  color: #b5b5b5;
  font-size: 14px;
}

.sep-menu.bottommenu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-top: 1px solid #e5e5e5;
  padding-top: 10px;
}

.search-btn {
  display: none !important;
}

// .ant-table-tbody :not(.expand-parent)  .user-info{
//      max-width: 500px;
//     white-space: initial;
//     min-width: 500px;
// }

@media only screen and (max-width: 1200px) {
  .foo-d-wrap {
    padding: 0 30px;

    h1 {
      font-size: 16px;
      line-height: 20px;
    }

    .foo-icon {
      img {
        max-width: 75px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  //   .ant-table-tbody .user-info{
  //       max-width: 350px;
  //     white-space: initial;
  //     min-width: 350px;
  // }

  .search-btn {
    display: block !important;
  }

  .profile-card .profile-photo .user-name {
    font-size: 16px;
    margin-left: 100px !important;
  }

  .profile-card .profile-photo .ant-avatar {
    top: -8px !important;
  }

  body .bswDAJ .ant-page-header-heading-title {
    font-size: 1.2rem !important;
  }

  .mob-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pt-100 {
    padding-top: 70px;
  }
  .pb-150 {
    padding-bottom: 120px;
  }

  .foo-d-wrap {
    padding: 0 30px;

    h1 {
      font-size: 16px;
      line-height: 20px;
    }

    .foo-icon {
      img {
        max-width: 75px;
      }
    }
  }

  .s-15 {
    margin-bottom: 15px;
  }

  .profile-card .ant-card-grid {
    padding: 15px 24px !important;
  }

  .search-wrapper {
    flex-direction: column;
    align-items: normal;
    display: block;

    small {
      margin-bottom: 10px;
      display: block;
    }

    &.mobile {
      background-color: #fff;
      padding: 30px 20px 20px;
      border: 1px solid #ddd;
      border-radius: 10px;
      margin: 0 !important;
      box-shadow: 5pt 5pt 14pt rgba(0, 0, 0, 0.2);
      margin-top: -20px !important;
    }
  }
  .search-form {
    .ant-row {
      flex-direction: column;
    }
    .ant-col {
      flex: 1 !important;
      margin-bottom: 10px !important;
    }
  }
}

.search-btn svg {
  width: 18px !important;
  height: 18px !important;
  color: #000 !important;
}

// .search-transition-enter {
//   opacity: 0;
//   transform: translateY(-10%);
// }

// .search-transition-enter-active {
//   opacity: 1;
//   transform: translateY(0);
//   transition: all 0.3s;
// }

// .search-transition-exit {
//   opacity: 1;
//   transform: translateY(0);
// }

// .search-transition-exit-active {
//   opacity: 0;
//   transform: translateY(-10%);
//   transition: all 0.3s;
// }

// .search-transition-exit-done {
//   display: none;
// }

// .search-transition-enter-done {
//   display: block;
// }

.search-transition-enter {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  overflow: hidden;
}

.search-transition-enter-active {
  opacity: 1;
  max-height: 600px;
  transition: all 300ms ease-in;
}

.search-transition-exit {
  opacity: 1;
  max-height: 600px;
}

.search-transition-exit-active {
  opacity: 0;
  max-height: 0;
  transition: all 300ms ease-in;
}

@media (max-width: 599.98px) {
  .stage-button {
    margin-top: 10px;
  }

  //     .ant-table-tbody .user-info{
  //       max-width: 280px;
  //     white-space: initial;
  //     min-width: 240px;
  // }
}

.max-94 svg {
  max-width: 94%;
}

.ant-table-footer {
  border-radius: 0 0 8px 8px;
}

.coupon-input input {
  padding: 5px;
  height: 48px;
  border-radius: 2px 0 0 2px;
}

.coupon-input button {
  height: 48px !important;
  border-radius: 0 2px 2px 0;
}

.success-text {
  color: #00a854;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
}
.upload-text {
  font-size: 16px;
  span {
    color: #e54f96;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.step-container .step-button {
  cursor: pointer !important;
}

.tbl-label.paid {
  background-color: #80ffc1 !important;
  color: #000 !important;
}
.tbl-label.cancelled {
  background-color: #ff8080 !important;
  color: #000 !important;
}

.domain-input {
  input {
    height: 40px;
  }

  p {
    font-size: 10px;
    color: #717171;
    margin: 5px 0 !important;
  }

  span.label {
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
  }
}

.domain-strap {
  width: 5px;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background-color: green;
  border-radius: 5px 0 0 5px;
}

.domain-message {
  padding: 15px 25px;
  background: #fff9f9;
  margin: 10px -25px 0;
  p {
    margin-bottom: 0;
  }
  b {
    display: block;
    margin-bottom: 5px;
  }
}

.domain-card .ant-card-body {
  padding-bottom: 10px !important;
}

.instruction-list {
  padding: 0 20px;
  li {
    display: block;
    border-bottom: 1px solid #e8e8e8;
    padding: 25px 0;
  }
}

.stepinfo {
  padding: 10px;
}

.forcast-card-box .ant-card {
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  color: #272b41;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  border-bottom: 1px solid #f3f3f3;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.logosite,
.logosite:hover {
  color: #000;
}

.focard-status__percentage {
  color: rgb(32, 201, 151);
  font-size: 16px;
}

.point-span {
  margin-top: 12px;
  margin-bottom: 0px;
  color: #009bd1;
  span {
    font-size: 13px;
    font-weight: 400;
    color: #6f6f6f;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 0px;
  }
}

.badge {
  width: 70px;
  height: 70px;
  div {
    width: 100px;
    height: 100px;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 70px;
    height: 70px;

    div {
      width: 105px;
      height: 105px;
    }
  }
}

.dashboard-widget {
  .ant-card-head {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .ant-table-content {
    padding: 15px !important;

    .ant-table-thead tr th {
      border: none !important;
      padding: 0px !important;
      padding-bottom: 7px !important;

      &:before {
        content: '';
        display: none !important;
      }
    }
  }
  .ant-table-tbody > tr {
    td {
      border-bottom: 0px !important;
      padding: 7px 0px 7px 0px !important;
      border: none !important;
    }
  }

  &.resport-page {
    tr td {
      border-bottom: 1px solid #f4f4f4 !important;
      padding: 10px !important;
    }
    .ant-table-thead tr th {
      background-color: #f0f0f0 !important;
      padding: 5px 10px !important;
      color: #333333 !important;
    }

    .ant-table-content {
      padding: 25px !important;
    }

    &.no-padding {
      .ant-table-content {
        padding: 20px 0 0 !important;
      }
    }

    &.model {
      .ant-table-content {
        padding: 0px !important;
      }
    }
  }
}

.report-model-title {
  padding: 10px px 0;
  font-size: 16px;
  color: #020202;
}

.contact-widget {
  .ant-table-thead .ant-table-cell:last-child {
    text-align: right !important;
  }
}

.filter-button .buttons-lists {
  .btn {
    display: inline-block;
    background-color: #f4f4f4;
    padding: 2px 8px;
    border-radius: 5px;
    font-size: 13px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;

    &.active {
      background-color: #e0007a;
      color: #fff;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.card-count .ant-card-body {
  padding: 20px !important;
}

.ant-select-dropdown-menu-item-divider {
  border-bottom: 1px solid #e8e8e8; /* Adjust as needed */
  margin: 0px !important;
  padding: 0px !important;
  min-height: 1px !important;
  margin: 10px 0px !important;
  cursor: auto !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-color: #fff0f4 !important;
}

.with-border {
  .ant-card-head {
    border-bottom: 1px solid #f0f0f0 !important;
  }
}

.daasho.active a {
  color: #e54f96 !important;
}

canvas {
  max-width: 100%;
  min-width: 100%;
}

// .forcast-card-box {
//   margin-bottom: 20px;
// }

@media (max-width: 767px) {
  .ant-card-head-title {
    overflow-x: scroll;
    max-width: 100%;
  }
  .mob-100 {
    width: 100% !important;
  }
}

@media (max-width: 599.98px) {
  .ant-card-head-wrapper {
    display: block !important;
    width: 100% !important;
  }

  .ant-card-extra {
    padding-top: 0px !important;
  }
}

.online-funnel {
  width: 400px;
  display: flex;
  justify-content: space-between;
  gap: 5px;

  @media (max-width: 599.98px) {
    width: 100%;
    display: block !important;

    .ant-col {
      padding: 0px !important;
      margin-bottom: 10px !important;
    }
  }
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #000 !important;
}

.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item a.current {
  color: #e54f96 !important;
}

.chart-alert-info .ant-alert-description {
  font-size: 12px !important;
  line-height: 18px !important;
}

.dashboard-widget.no-send {
  @media (max-width: 767.98px) {
    .ant-card-head-title {
      width: 100%;
      border-bottom: 1px solid #ebebeb;
    }
    .ant-card-extra {
      display: none !important;
    }

    .ant-table-content {
      padding-top: 10px !important;
    }
  }
}

@media (max-width: 991.98px) {
  .t-contact{
    margin-bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .ant-card-head-title,
  .ant-card-extra {
    width: 100%;
  }
  .time-range-select-button {
    width: 100%;
  }
  .ant-page-header-heading-sub-title {
    text-overflow: initial;
    white-space: inherit;
  }

  .wrap-head {
    max-width: 100%;
    white-space: wrap;
  }

  .m-mb-20 {
    margin-bottom: 20px !important;
  }

  .mb-extra-select {
    flex-direction: column;
  }

  .filter-button .buttons-lists {
    .btn {
      width: 48%;
    }
  }

  .wrap-title .ant-card-head .ant-card-head-title {
    max-width: 100%;
    white-space: break-spaces;
  }

  .choose-task-type {
    flex-direction: column;
    align-items: start;
    .ant-space-item {
      width: 100%;
    }

    .buttons-lists {
      .btn {
        width: 48%;
      }
    }
  }

  .profile-card .ant-card-head .ant-card-extra {
    top: 24px;
    right: 20px;
    position: absolute;
    justify-content: end;
    z-index: 99;
  }

  .ant-select {
    width: 100% !important;
  }
}

.ant-card-body .select-statuis .ant-select-selector {
  background-color: #ededed !important;
  padding: 5px 20px !important;
  height: auto !important;
  border-radius: 30px !important;
  border: none !important;
}

.head-border .ant-card-head-wrapper {
  .ant-card-head-title {
    border-bottom: 1px solid #ebebeb;
  }
  .ant-card-extra {
    display: none !important;
  }
}

.kekthn table tbody tr td span.overdue {
  color: #ff8080;
  font-weight: 600;
}

.table-actions button.shared .feather {
  color: #e54f96 !important;
}

.table-actions button {
  margin-left: 15px;
}

.ant-btn-background-ghost.ant-btn-primary.lined {
  text-transform: uppercase;
  border-color: #000;
  border-width: 2px;
  color: #000;
  border-radius: 5px;
  font-weight: 600;
  padding: 10px 20px;
  height: auto;
}

.max-1000 {
  max-width: 1000px;
  margin: auto;
}

.business-budget-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: right;
  padding: 20px;

  h2 {
    margin-bottom: 0;
    font-size: 16px;

    span {
      color: #e54f96;
    }
  }
}

.green {
  color: #00a854 !important;
}

.field-container {
  position: relative;
  display: flex;
}

.field-container .form-control {
  flex-grow: 1;
}

.field-container .clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #7a7a7a;
  border: 1px solid #ccc;
  width: 18px !important;
  height: 18px;
  min-width: 18px !important;
  padding: 0;
  line-height: 1;
  svg {
    margin-bottom: -2px !important;
  }
}

button.shared svg {
  color: #e54f96;
}

.no-cancel {
  .ant-modal-close-x {
    display: none !important;
  }
}

.semibold{
  font-weight: 600;
}

.gap-5{
  gap: 10px;
}

.px-15{
  padding: 0 15px !important;
}

.py-10{
  padding: 10px 0 !important;
}

.stage-wrapper{
  .edit-button{
    display: none;
    position: absolute;
    top: 0;
  }

  &:hover{
    .edit-button{
      display: block;
      top: 0;
    }
  }
}

.table-responsive.wrap-header .ant-table-content .ant-table-cell{
  white-space: break-spaces;
}
.no-cursor{
  cursor: auto !important;
}


.premium-card .ant-card-cover{
  background-color: #F6F7F7;
  width: 50%;
  min-width: 50%;
}

.no-mar{
  svg{
    margin: 0 !important;
  }
  
  .t-1 svg{
    margin-bottom: 2px!important;
  }
}

.custom-text textarea.ant-input{
  min-height: 40px !important;
}

.ico svg{
  margin-right: 5px !important;
}
@primary-color: #E54F96;